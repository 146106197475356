import React, { useState } from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import Img01 from "../../../images/Property/getting01.png"
import Img02 from "../../../images/Property/getting02.png"
import Img03 from "../../../images/Property/getting03.png"
import PlayVideo from '../../../components/Play/PlayVideo';
import ScrollAnimation from 'react-animate-on-scroll';

import "./Info.scss"
const Info = () => {
    const [isPlay, setPlay] = useState(false);

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-up" : "icon-down"
                    }
                ></i>
            </button>
        )
    }
    return (
        <React.Fragment>
            <section className="info">
                <ScrollAnimation animateIn="fadeInUp">
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <h2>Good to know</h2>
                                <Accordion >
                                    <Card>
                                        <div className="accordion-header card-header">
                                            <ContextAwareToggle eventKey="0" className="accordion-header">
                                                <div className="heading">
                                                    <i className="icon-calculator"></i>
                                                    <h4>How much stamp duty will I need to pay on this home?</h4>
                                                </div>

                                            </ContextAwareToggle>
                                        </div>
                                        <Accordion.Collapse eventKey="0">
                                            <div className="accordion-card accordion-card-first card-body">
                                                <div className="accordion-list">
                                                    <div className="list-wrapper">
                                                        <span>Utility Room</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 4</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 5/Gym</span>
                                                        <span className="gym">11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Office</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Snug</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Kitchen/Diner</span>
                                                        <span>11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <div className="accordion-header card-header">
                                            <ContextAwareToggle eventKey="1" className="accordion-header">
                                                <div className="heading">
                                                    <i className="icon-calculator"></i>
                                                    <h4>How much could I earn as a landlord for this property?</h4>
                                                </div>

                                            </ContextAwareToggle>
                                        </div>
                                        <Accordion.Collapse eventKey="1">
                                            <div className="accordion-card accordion-card-first card-body">
                                                <div className="accordion-list">
                                                    <div className="list-wrapper">
                                                        <span>Utility Room</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 4</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 5/Gym</span>
                                                        <span className="gym">11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Office</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Snug</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Kitchen/Diner</span>
                                                        <span>11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <div className="accordion-header card-header">
                                            <ContextAwareToggle eventKey="2" className="accordion-header">
                                                <div className="heading">
                                                    <i className="icon-epc"></i>
                                                    <h4>EPC</h4>
                                                </div>
                                            </ContextAwareToggle>
                                        </div>
                                        <Accordion.Collapse eventKey="2">
                                            <div className="accordion-card accordion-card-first card-body">
                                                <div className="accordion-list">
                                                    <div className="list-wrapper">
                                                        <span>Utility Room</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 4</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 5/Gym</span>
                                                        <span className="gym">11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Office</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Snug</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Kitchen/Diner</span>
                                                        <span>11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Card>


                                    <Card>
                                        <div className="accordion-header card-header">
                                            <ContextAwareToggle eventKey="3" className="accordion-header">
                                                <div className="heading">
                                                    <i className="icon-internet"></i>
                                                    <h4>Broadband Speed</h4>
                                                </div>

                                            </ContextAwareToggle>
                                        </div>
                                        <Accordion.Collapse eventKey="3">
                                            <div className="accordion-card accordion-card-first card-body">
                                                <div className="accordion-list">
                                                    <div className="list-wrapper">
                                                        <span>Utility Room</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 4</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Bedroom 5/Gym</span>
                                                        <span className="gym">11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Office</span>
                                                        <span>10’11 x 8’7 (3.33m x 2.62m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Snug</span>
                                                        <span>8’6 x 6’4 (2.59m x 1.93m)</span>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <span>Kitchen/Diner</span>
                                                        <span>11’8 x 8’2 (3.56m x 2.49m)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Card>



                                </Accordion>
                                <div className="getting">
                                    <div className="getting-img">
                                        <picture>
                                            <source media="(min-width:992px)" srcset={Img03} />
                                            <source media="(min-width:768px)" srcset={Img02} />
                                            <img className="bg-img" src={Img01} alt="Img" />
                                        </picture>
                                        <button type="button" title="play" className="btn-play" onClick={(e) => { setPlay(true) }}>
                                            <i className="icon-play"></i>
                                        </button>
                                    </div>
                                    <div className="details">
                                        <span className="sm-text">Getting to know</span>
                                        <h2>Dover</h2>
                                        <p>The history of Dover is central to that of our country.
                                            Thanks its location, just 21 miles across the Strait of Dover from France,
                                            it became an important port during the Roman period. Later, it would become one of the original Cinque Ports,
                                            afforded special privileges in return for the use of its ships by the King.</p>
                                        <div className="link-wrapper">
                                            <Link href="#" className="view">View the Dover Area Guide</Link>
                                            <Link href="#">
                                                <i className="icon-arrow"></i>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId="V8ksSGxo6no"
                isAutoPlay={1}
            />
        </React.Fragment>
    )
}
export default Info
